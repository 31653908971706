<script setup lang="ts">
    import Checkbox from 'primevue/checkbox';
    import Message from 'primevue/message';
    import { componentStyle } from '@/styling/util/component-style.ts';
    import { v4 } from 'uuid';

    defineProps<{
        errorMessage?: string;
    }>();

    const messageStyle = componentStyle({
        textFontWeight: '400',
    });

    const id = v4();
</script>

<template>
    <div>
        <div class="checkbox-container">
            <Checkbox :input-id="id" v-bind="$attrs" />
            <label :for="id" class="checkbox-label">
                <slot></slot>
            </label>
        </div>
        <Message
            v-if="errorMessage != null && errorMessage !== ''"
            :dt="messageStyle"
            severity="error"
            size="small"
            variant="simple"
            >{{ errorMessage }}</Message
        >
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .checkbox-container {
        display: flex;
        gap: main.$spacing-3;
        align-items: center;
    }

    .checkbox-label {
        cursor: pointer;
    }
</style>
